import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';


import { ReactComponent as Arrow } from '../assets/arrow.svg';
import GalleryHeader from '../components/GalleryHeader';
import GalleryFooter from '../components/GalleryFooter';
import { API_URL } from '../constant';

import '../styles/PaintingDetails.css'
import Cursor from '../components/Cursor';

export default function PaintingDetails() {

  const navigate = useNavigate();

  const {id} = useParams();
  const [isDraging, setIsDraging] = useState(false);
  const [paintingScrollerOffset, setPaintingScrollerOffset] = useState(0);

  const [paintingMoveDone, setPaintingMoveDone] = useState(false);

  const [painting, setPainting] = useState({});


  const mouseDown = (e) => {
    setIsDraging(true);
  };
  const mouseUp = (e) => {
    setIsDraging(false);
    setPaintingMoveDone(false);
  };


  let maxs = [0, 0];

  const paintingMouseMove = (e) => {
    if (!isDraging) return;
    if (paintingMoveDone) return;

    setPaintingMoveDone(true);
    
    if (e.movementX > 0) movePaintingScroller(-1)
    else movePaintingScroller(1)
      
  };

  const movePaintingScroller = movement => {
    if (paintingScrollerOffset + movement < maxs[0] || paintingScrollerOffset + movement > maxs[1]) return;
    setPaintingScrollerOffset(paintingScrollerOffset + movement);
  }

  const generatePaintingScroller = (painting) => {
    let images = [];
    const paintings = [];
    for (let i = 1; i <= painting.zoom_number; i++) {
      paintings.push(API_URL + '/static/placeholder/zoom' + i + '.png');
    }
    const middle = Math.floor(paintings.length / 2);
    maxs = [-middle, paintings.length - middle];
    let offset = 0;
    for (let i = maxs[0]; i < maxs[1]; i++) {
      if(i == 0) offset++;
      images.push(
      <div 
        key={i + offset}
        className={`uniqueScrollerPaintingContainer`}
      >
        <img 
            src={paintings[i+ middle]}
            className="uniqueScrollerPainting"
            style={{
              transform: paintingScrollerOffset - 1 == i + offset ? 'rotateY(15deg)' : paintingScrollerOffset + 1 == i + offset ? 'rotateY(-15deg)' : ''
            }}
          />
      </div>)      
    }
    const middlePart = (<div 
        className={`uniqueScrollerPaintingContainer`}
        key="0"
      >
        <img 
          src={API_URL + '/static/painting/' + id + '.png'}
          className="uniqueScrollerPainting"
          style={{
            transform: paintingScrollerOffset - 1 == 0 ? 'rotateY(15deg)' : paintingScrollerOffset + 1 == 0 ? 'rotateY(-15deg)' : ''
          }}
        />
      </div>);

    const ifOdd = (<div className={`uniqueScrollerPaintingContainer`} key={paintings.length}></div>);

    const firstPart = images.slice(0, middle)
    const secondPart = images.slice(middle);

  
    let toReturn = [...firstPart, middlePart, ...secondPart];

    if (paintings.length % 2 != 0) toReturn = [ifOdd, ...toReturn];

    return toReturn;
  }

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(API_URL + '/paintings/' + id);
      const data = await response.json();
      
      if (data.length === 0) navigate('/notFound', {replace: true});

      data[0].price = data[0].price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      setPainting(data[0]);

    }
  
    fetchData().catch(console.error);
  }, []);

  return (
    <div className='gallery'>
      <GalleryHeader backAction={() => navigate(-1)} />
      <div className='thumbsGalleryContent'
        onMouseDown={e => mouseDown(e, 'thumbs')}
        onMouseUp={mouseUp}
        onMouseMove={paintingMouseMove}
      >
        <div
          className="paintingScroller"
        >
          <div
            className="paintingScrollerContainer"
            style={{
              transform: `translateX(calc(-50% - calc(calc(30vw + 314px) * ${paintingScrollerOffset})))`,
              transition: 'transform .5s'
            }}
          >
            {generatePaintingScroller(painting)}
          </div>
          <div
            className={`arrow left`}
            onClick={() => movePaintingScroller(-1)}
            style={{
              opacity: paintingScrollerOffset == maxs[0] ? '0' : ''
            }}
          >
            <div className="outer">
              <Arrow />
            </div>
          </div>
          <div
            className={`arrow right`}
            onClick={() => movePaintingScroller(1)}
            style={{
              opacity: paintingScrollerOffset == maxs[1] ? '0' : ''
            }}
          >
            <div className="outer">
              <Arrow />
            </div>
          </div>
        </div>
      </div>
      <GalleryFooter type={'painting'} details={painting} />
      <Cursor />
    </div>
  )

}