/**
 * Return a thruthy value if number given is strictly superior to min and inferior or equal to max
 * @param {Number} number Number to compare
 * @param {Number} min Minimum (need to be strictly greater)
 * @param {Number} max Maximum (can be equal to)
 * @returns {Boolean} condition result
 */
export const between = (number, min, max) => {
  if (number > min && number <= max) return true;
  else return false;
}

/**
 * Return whether or not the screen is in portrait orientation
 * @returns {Boolean} True if portrait, else return false
 */
export const isPortrait = () => {
  if (window.screen.orientation.type === 'landscape-primary' ||
    window.screen.orientation.type === 'landscape-secondary') return false;
  else return true;
}

/**
 * Generate a random number between min and max provided
 * @param {Number} min Minimum number
 * @param {Number} max Maximum number
 * @returns {Number} Number generated
 */
export const getRandom = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}