export default {
  scroller: 'SCROLL DOWN',
  buy: 'ACQUIRE FOR',
  ar: {
    title: 'PLACE IN YOUR ROOM',
    subtitle: 'Augmented reality',
  },
  options: {
    scroll: 'Scroll',
    thumbs: 'Thumbs',
  }
}