import { useState } from 'react';

import { isPortrait } from '../utils';

import image1 from '../assets/paintings/1.png';

import '../styles/BurgerMenu.css';

import i18n from '../i18n'
import { useTranslation } from 'react-i18next';

export default function BurgerMenu(props) {

  const [hoverNumber, setHoverNumber] = useState(1);

  const { t } = useTranslation();


  const calculateTop = () => {
    let transformNumber = 0;
    switch (hoverNumber) {
      case 1:
        transformNumber = -280;
        break;
      case 2:
        transformNumber = -115;
        break;
      case 3:
        transformNumber = 30;
        break;
      case 4:
        transformNumber = 195;
        break;
    }
    return transformNumber;
  }

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  }

  const generateMenuItems = () => {
    let items = []
    for (let i = 1; i <= 4; i++) {
      items.push(
        <div 
          className="element"
          onMouseOver={() => {setHoverNumber(i)}}
          style={{
            transform: !isPortrait() && hoverNumber === i ? 'translateX(35%)' : 'translateX(0)',
            transition: '.3s'
          }}
        >
          <h2>{t('burger.items.'+i+'.subtitle')}</h2>
          <h1>
            {props.currentPage == i ? '[ ' : ''}
            {t('burger.items.'+i+'.title')}
            {props.currentPage == i ? ' ]' : ''}
          </h1>
        </div>
      )
    }
    return items;
  }

  return (
    <div className="burger">
      <div className="content">
        {generateMenuItems()}
      </div>
      <footer>
        <div className="links">
          <div>[ {t('burger.links.legal')} ]</div>
          <div>[ {t('burger.links.privacy')} ]</div>
        </div>
        <div className="lang">
          <div onClick={() => changeLanguage('fr')} className={i18n.language == 'fr' ? 'selected' : ''}>[ FR ]</div>
          <div onClick={() => changeLanguage('en')} className={i18n.language == 'en' ? 'selected' : ''}>[ EN ]</div>
        </div>
      </footer>
      { !isPortrait() &&
        <img 
          className="selector"
          src={image1}
          style={{
            transform: `translateX(-140%) translateY(${calculateTop()}%)`,
            transition: '.3s'
          }}
        />
      }
    </div>
  )
}