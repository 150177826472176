import { useState } from 'react';

import { ReactComponent as BurgerElement } from '../assets/burgerElement.svg';

import '../styles/BurgerTrigger.css';
import BurgerMenu from './BurgerMenu';

export default function BurgerTrigger(props) {

  const [burgerOpened, setBurgerOpened] = useState(false);

  const burgerClick = () => {
    setBurgerOpened(!burgerOpened)
  }

  return (
    <>
      <div onClick={burgerClick} className={`burgerTrigger ${burgerOpened ? 'opened' : ''}`}>
        <BurgerElement />
        <BurgerElement />
      </div>
      {burgerOpened && <BurgerMenu currentPage={props.currentPage} /> }
    </>
  )
}