import { between, isPortrait } from "../utils";

export default function CollectionItem(props) {

  const {
    imageSource,
    min,
    zoomOffset,
    currentState,
    transitionPhase,
    style,
    startingPoint,
    click,
  } = props;

  const state = (val, ratio, offset, phase, before, after) => {
    if (between(currentState, min+offset, min+offset+phase)) return (val - (min+offset)) / ratio;
    else if (currentState <= min+offset) return before;
    else return after;
  }

  const generateBlur = (val) => {
    const base = 5 - state(currentState, transitionPhase, 0, transitionPhase, 0, 5);

    let next = base;

    const zoom = state(val, 0.10, transitionPhase+zoomOffset, 100, 0, 200);

    
    if (zoom >= 390) {
      next = state(val, 1, transitionPhase+zoomOffset, 10, 0, 5)
    }

    return next;
  }

  const generateOpacity = (val) => {
    const base = state(val, transitionPhase, 0, transitionPhase, 0, 1);

    let next = base;

    const zoom = state(val, 0.10, transitionPhase+zoomOffset, 100, 0, 200);

    if (zoom >= 390) {
      next = 2 - state(val, 3, transitionPhase+zoomOffset, 10, 0, 2)
    }
 
    return next;
  }
  return (
    <div
      onMouseEnter={(e) => {
        if (generateOpacity(currentState) == 1) return props.hover();
      }}
      onMouseLeave={props.notHover}
      className="scrollPaintingContainer"
      style={{
        
        opacity: `${generateOpacity(currentState)}`,
  
        filter: `blur(${generateBlur(currentState)}px)`,

        transform: `translateZ(${startingPoint + state(currentState, 0.10, transitionPhase+zoomOffset, 1000, 0, 200)}px) translateX(${isPortrait() ? 130 : 0}px)`,

        transition: '.2s',

        ...style
      }}
      onClick={click}
    >
      <img 
        style={{
          width: '100%',
  
          display: 'block',
  
        }}
        src={imageSource}
      />
    </div>
  );

}