import React from 'react';
import ReactDOM from 'react-dom/client';

import {
  createBrowserRouter,
  Link,
  RouterProvider,
} from "react-router-dom";

import './index.css';

import Gallery from './pages/Gallery';
import PaintingDetails from './pages/PaintingDetails';
import ErrorPage from './pages/ErrorPage';

import './i18n'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Link to="/visit">Start Visiting</Link>,
  },
  {
    path: '/visit',
    element: <Link to="/visit/1">Base</Link>
  },
  {
    path: '/visit/:selectedRoute',
    element: <Gallery />
  },
  {
    path: '/painting/:id',
    element: <PaintingDetails />
  },
  {
    path: '*',
    element: <ErrorPage />
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <RouterProvider router={router} />
  </>
);
