import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Logo404 } from '../assets/404.svg';
import { ReactComponent as Arrow } from '../assets/arrow2.svg';

import GalleryFooter from '../components/GalleryFooter';
import GalleryHeader from '../components/GalleryHeader';

import '../styles/ErrorPage.css';
import { useTranslation } from 'react-i18next';

export default function ErrorPage() {

  const navigate = useNavigate();

  const irisRef = useRef();

  const { t } = useTranslation();


  const [irisTrans, setIrisTrans] = useState([0, 0]);

  const mouseMove = useCallback((e) => {
    const relY = e.clientY - irisRef.current.offsetTop + 36;
    const relX = e.clientX - window.innerWidth/2;
    let newTransX;
    let newTransY;
    if (relY < 0) {
      const transY = -relY*-30/(irisRef.current.offsetTop + 36);
      newTransY = transY;
    } else {
      const transY = relY*30/(window.innerHeight - (irisRef.current.offsetTop + 36));
      newTransY = transY;
    }
    
    if (relX < 0) {
      const transX = -relX*-30/(irisRef.current.offsetLeft + 36);
      newTransX = transX;
    } else {
      const transX = relX*30/(window.innerWidth - (irisRef.current.offsetLeft + 36));
      newTransX = transX;
    }

    setIrisTrans([newTransX, newTransY])
  });


  useEffect(() => {
    document.addEventListener('mousemove', mouseMove);
    return () => {
      document.removeEventListener('mousemove', mouseMove);
    };
  }, [mouseMove])


  return (
    <div className="errorPage">
      <GalleryHeader backAction={() => navigate(-1)} />
      <div className='errorContent'>
        <div className='code'>
          <span>4</span>
          <div className='eye'>
            <div className='iris' ref={irisRef} style={{transform: `translate(${irisTrans[0]}%, ${irisTrans[1]}%)`}}></div>
          </div>
          <span>4</span>
        </div>
        <h1>
          {t('common.error')}
        </h1>
        <div className='button' onClick={() => navigate(-1)}>
          <Arrow />
          <p>{t('common.back')}</p>
        </div>
      </div>
      <GalleryFooter type="error" />
    </div>
  )
}