import common from './translation';
import gallery from './gallery';
import burger from './burger';
import footer from './footer';
import header from './header';

export default {
  common,
  gallery,
  burger,
  footer,
  header,
}