export default {
  scroller: 'DEFILEZ VERS LE BAS',
  buy: 'ACHETER POUR',
  ar: {
    title: 'PLACER DANS VOTRE PIECE',
    subtitle: 'Realitée augmentée',
  },
  options: {
    scroll: 'Defiler',
    thumbs: 'Pouces',
  }
}