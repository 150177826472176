export default {
  items: {
    1: {
      subtitle: 'LA',
      title: '1.GALERIE',
    },
    2: {
      subtitle: 'LE',
      title: '2.MUR D\'ART',
    },
    3: {
      subtitle: 'DECOUVREZ',
      title: '3.L\'ART LEASING',
    },
    4: {
      subtitle: 'PLUS',
      title: '4.A PROPOS DE NOUS',
    },
  },
  links: {
    legal: 'MENTIONS LEGALES',
    privacy: 'CONFIDENTIALITE',
  },
}