import { useEffect, useRef } from "react";
import { isPortrait } from "../utils";

import '../styles/Cursor.css'

export default function Cursor(props) {

  const dotRef = useRef();

  let mouseX = -500,
      mouseY = -500;
      
  let localX = mouseX,
      localY = mouseY;

  let mouseMoved = false;

  const mouseMove = e => {
    if (dotRef.current === null) return;
    mouseX = e.clientX;
    mouseY = e.clientY;
    if (!mouseMoved) {
      dotRef.current.style.top = `${mouseY}px`;
      dotRef.current.style.left = `${mouseX}px`;
      localX = mouseX;
      localY = mouseY;
      mouseMoved = true;
    }
  }
  
  const requestMove = async () => {
    if (dotRef.current === null) return;

    localX = localX - ((localX - mouseX) / 8);
    localY = localY - ((localY - mouseY) / 8);
    
    dotRef.current.style.top = `${localY}px`;
    dotRef.current.style.left = `${localX}px`;
    
    requestAnimationFrame(requestMove)
  }
  
  useEffect(() => {
    document.addEventListener("mousemove", mouseMove);
    requestAnimationFrame(requestMove)
    return () => {
      document.removeEventListener("mousemove", mouseMove);
    }
  }, [dotRef]);

  return (
    <div
      ref={dotRef}
      className="cursor"
    >
      {!isPortrait() &&
        <>
          <div className="dot" />
          <div className={props.outer ? 'outer' : 'outer hidden'} />
          <div className={props.outer ? 'inner' : 'inner hidden'} />
        </>
      }
    </div>
  )
}