import { useTranslation } from 'react-i18next';

import { isPortrait } from '../utils';

import BurgerTrigger from './BurgerTrigger';

import { ReactComponent as Logo } from '../assets/logo.svg';

import '../styles/GalleryHeader.css';

export default function GalleryHeader(props) {

  const { t } = useTranslation();

  return (
    <>
      <header className='gallery'>
        <div className='options'>
          { !isPortrait() &&
            <h2 onClick={props.backAction}>
              { t('header.back') }
            </h2>
          }
          <BurgerTrigger currentPage={props.currentPage} />
        </div>
        <Logo className="logo" />
      </header>
    </>
  )
}