export default {
  items: {
    1: {
      subtitle: 'THE',
      title: '1.GALLERY',
    },
    2: {
      subtitle: 'THE',
      title: '2.PAINT WALL',
    },
    3: {
      subtitle: 'DISCOVER',
      title: '3.ART LEASING',
    },
    4: {
      subtitle: 'MORE',
      title: '4.ABOUT US',
    },
  },
  links: {
    legal: 'LEGAL MENTIONS',
    privacy: 'PRIVACY',
  },
}