import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getRandom, isPortrait } from "../utils";

import { API_URL } from '../constant';

import CollectionItem from "../components/CollectionItem";
import GalleryFooter from "../components/GalleryFooter";
import GalleryHeader from "../components/GalleryHeader";

import image1 from '../assets/paintings/1.png';
import image2 from '../assets/paintings/2.png';
import image3 from '../assets/paintings/3.png';
import image4 from '../assets/paintings/image4.png';
import image5 from '../assets/paintings/image5.png';

import '../styles/Gallery.css';
import Cursor from "../components/Cursor";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";

export default function Gallery(props) {

  const { selectedRoute } = useParams();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [blur, setBlur] = useState(0);

  const [scrollCount, setScrollCount] = useState(0);

  const [isDraging, setIsDraging] = useState(false);

  const [thumbsOffset, setThumbsOffset] = useState(0);
  const [previousThumbsOffset, setPreviousThumbsOffset] = useState(0);

  const thumbsRef = useRef();

  const [imageHovered, setImageHovered] = useState();

  const [scrollImageHovered, setScrollImageHovered] = useState();
  const [isScrollImageHovered, setIsScrollImageHovered] = useState();


  const [galleryType, setGalleryType] = useState('scroll');


  const [touchStartCoord, setTouchStartCoord] = useState();
  const [thumbsTouchCounter, setThumbsTouchCounter] = useState(0);

  const [touched, setTouched] = useState(false);

  const [imageList, setImageList] = useState([]);

  const [thumbsHover, setThumbsHover] = useState(false);
  
  const wheel = useCallback((e) => {
    const deltaSign = Math.sign(e.wheelDelta);

    scrollerMove(deltaSign);
  })



  const scrollerMove = (deltaSign) => {
    if (galleryType != 'scroll') return;

    if (deltaSign === 1 && scrollCount === 0) return;

    if (scrollCount >= 0 && scrollCount <= 5) {
      setBlur(scrollCount);
    }
      
    if (scrollCount >= 10) {
      setScrollCount(scrollCount - deltaSign - deltaSign - deltaSign - deltaSign);
    } else {
      setScrollCount(scrollCount - deltaSign);
    }
  }

  
  const mouseDown = (e) => {
    if (['thumbs', 'painting'].indexOf(galleryType) == -1) return;
    setIsDraging(true);
    setPreviousThumbsOffset(thumbsOffset);
  };
  const mouseUp = (e) => {
    if (['thumbs', 'painting'].indexOf(galleryType) == -1) return;
    setIsDraging(false);
  };

  const thumbsMouseMove = (e) => {
    if (!isDraging) return;

    const movement = e.movementX;

    const max = -thumbsRef.current.scrollWidth + window.innerWidth;
    if (thumbsOffset + movement > 0 || thumbsOffset + movement < max) return;
    thumbsRef.current.style.transform = `translateX(${thumbsOffset + movement}px)`;
    setThumbsOffset(thumbsOffset + movement);    
  };


  

  const thumbsPhoneMove = (delta) => {
    if (touched) return;
    
    setTouched(true);
    
    if(thumbsTouchCounter - delta < 0 || thumbsTouchCounter - delta > thumbsRef.current.children.length - 1) return;

    const move = thumbsRef.current.children[thumbsTouchCounter].offsetWidth / 2 + thumbsRef.current.children[thumbsTouchCounter - delta].offsetWidth / 2 + 30;

    setThumbsOffset(thumbsOffset - move * -delta);
    setThumbsTouchCounter(thumbsTouchCounter - delta)
  }

  const touchStart = useCallback((e) => {
    setTouchStartCoord([e.touches[0].clientX, e.touches[0].clientY]);
  });

  const touchEnd = useCallback((e) => {
    setTouched(false)
  });

  let count = 0;
  const touchMove = useCallback((e) => {
    if (count <= 1) return count++;
    
    if (galleryType == 'scroll') {
      const delta = -Math.sign(touchStartCoord[1] - e.touches[0].clientY);
      
      scrollerMove(delta*2);
      
      count = 0;
    } else {
      const delta = -Math.sign(touchStartCoord[0] - e.touches[0].clientX);
      thumbsPhoneMove(delta)
    }
    
  })



  useEffect(() => {
    document.addEventListener("wheel", wheel);    
    document.addEventListener("touchstart", touchStart);    
    document.addEventListener("touchend", touchEnd);    
    document.addEventListener("touchmove", touchMove);    
    return () => {
      document.removeEventListener("wheel", wheel);
      document.removeEventListener("touchstart", touchStart);    
      document.removeEventListener("touchend", touchEnd);    
      document.removeEventListener("touchmove", touchMove);  
    }
  }, [wheel]);

  useEffect(() => {
    document.addEventListener("mouseout", mouseUp);
    const infos = window.sessionStorage.getItem('infos')
    

    const fetchData = async () => {
      const response = await fetch(API_URL + '/gallery/' + selectedRoute + '/getPaintings');
      const data = await response.json();
      
      if (data.length === 0) navigate('/notFound', {replace: true});

      for (let i = 0; i < data.length; i++) {
        data[i].rot = getRandom(-15, 15);
        if ((i+1) % 4 == 1) {
          data[i].top = getRandom(25, 40);
          data[i].left = getRandom(25, 40);
        } else if ((i+1) % 4 == 2) {
          data[i].top = getRandom(70, 85);
          data[i].left = getRandom(75, 85);
        } else if ((i+1) % 4 == 3) {
          data[i].top = getRandom(70, 85);
          data[i].left = getRandom(25, 40);
        } else if ((i+1) % 4 == 0) {
          data[i].top = getRandom(25, 40);
          data[i].left = getRandom(75, 85);
        }
      }

      setImageList(data)

      if (infos) {
        const data = JSON.parse(infos);
  
        setBlur(data.blur);
        setScrollCount(data.scrollCount);
        setThumbsOffset(data.thumbsOffset);
        setPreviousThumbsOffset(data.previousThumbsOffset);
        setGalleryType(data.galleryType);
        setThumbsTouchCounter(data.thumbsTouchCounter);
  
        window.sessionStorage.clear();
      }
    }
  
    fetchData().catch(console.error);
    return () => {
      document.removeEventListener("mouseout", mouseUp);  
    }
  }, []);

  useEffect(() => {
    if (isPortrait() && thumbsRef.current) {
      let baseOffset = (window.innerWidth - thumbsRef.current.children[0].offsetWidth - 30) / 2;
      setThumbsOffset(baseOffset);
    }
  }, [galleryType])


  const openPaintingInfos = (painting) => {
    window.sessionStorage.setItem('infos', JSON.stringify({
      blur,
      scrollCount,
      thumbsOffset,
      previousThumbsOffset,
      galleryType,
      thumbsTouchCounter,
    }))
    navigate('/painting/' + painting)
  }

  const populateScrollImages = () => {
    const images = [];
    for (let [index, image] of imageList.entries()) {
      images.push(<CollectionItem 
        key={index}
        imageSource={API_URL + '/static/painting/' + image.id + '.png'}
        min={8 + (isPortrait() ? 40 : 20) * index}
        currentState={scrollCount}
        zoomOffset={3}
        transitionPhase={3}
        startingPoint={isPortrait() ? -400 : -200}
        hover={(e) => {
          setScrollImageHovered(image);
          setIsScrollImageHovered(true);
        }}
        notHover={(e) => {
          setIsScrollImageHovered(false);
        }}
        style={{
          width: '300px',

          position: 'absolute',

          zIndex: 99 - index,

          rotate: `${image.rot}deg`,

          left: `calc(${image.left}% - 300px)`,
          top: `calc(${image.top}% - 300px)`,
        }}
        click={() => openPaintingInfos(image.id)}
      />)
    }
    return images;
  }

  

  const populateThumbsImages = () => {
    const images = [];
    for (let [index, image] of imageList.entries()) {
      images.push(
        <div className={`thumbsImageContainer ${index === thumbsTouchCounter ? '' : 'phoneHide'} hovered ${imageHovered && imageHovered != index+1 ? 'unhovered' : ''}`}
          onMouseEnter={(e) => {
            setImageHovered(index+1);
          }}
          onMouseLeave={(e) => {
            setImageHovered();
          }}
          onMouseUp={(e) => {
            if (previousThumbsOffset != thumbsOffset) return;
            openPaintingInfos(image.id);
          }}

          key={index}
        >
          <img
            src={API_URL + '/static/painting/' + image.id + '.png'}
            alt={`${image.title} by ${image.artist_name}`}
          />
          <div
            className={`descriptor ${imageHovered === index+1 ? 'hovered' : ''}`}
          >
            <h2>{image.title}</h2>
            <h3>{image.artist_name}</h3>
          </div>
        </div>);
    }

    return images;
  }






  const generateTitleSlider = imageList => {
    let images = [];
    for (let i = 0; i < 5; i++) {
      images.push(<img 
        key={i}
        src={imageList[i]}
        className="titlePainting"
        style={{
          animation: `titleImage${i} 10s forwards infinite`
        }}
      />)
    }
    return images;
  }

  


  

  return (
    <div className="gallery">
      <GalleryHeader currentPage={2} />
      {galleryType == 'scroll' &&
        <div
          className="scrollGalleryContent"
        >
          <h1 style={{
            filter: `blur(${scrollCount === 0 ? 0 : blur*2}px) opacity(${scrollCount === 0  ? 1 : 1 - (blur / 5)})`,
          }}
          >
            {t('gallery.hero.part1')}&emsp;&emsp;&emsp;&emsp;{t('gallery.hero.part2')}<br />
            {t('gallery.hero.part3')}
          </h1>
          <div
            style={{
              filter: `blur(${scrollCount === 0 ? 0 : blur*2}px) opacity(${scrollCount === 0  ? 1 : 1 - (blur / 5)})`,
              transform: i18n.language == 'en' ? `translateX(-${!isPortrait() ? '75' : '29'}px)` : `translateX(${!isPortrait() ? 20 : 7}px)`,
            }}
            className="titlePaintingContainer"
          >
            <div
              style={{
                position: 'relative',
              }}
            >
              {generateTitleSlider([image1, image2, image3, image4, image5])}
            </div>
          </div>
          
          {populateScrollImages()}

          
            <div className={`infos ${isScrollImageHovered ? '' : 'hidden'}`}>
              <h2>{scrollImageHovered?.title}</h2>
              <h3>{scrollImageHovered?.artist_name}</h3>
            </div>
        </div>
      }
      {['thumbs', 'painting'].indexOf(galleryType) != -1 &&
        <div 
          className={['thumbs', 'painting'].indexOf(galleryType) != -1 ? 'thumbsGalleryContent selected': 'thumbsGalleryContent'}
        >
          <div
            className="thumbsScroller"
            ref={thumbsRef}
            style={{
              transform: `translateX(${thumbsOffset}px)`
            }}
            onMouseDown={e => mouseDown(e, 'thumbs')}
            onMouseUp={mouseUp}
            onMouseMove={thumbsMouseMove}
            onMouseOver={() => setThumbsHover(true)}
            onMouseLeave={() => setThumbsHover(false)}
          >
            {populateThumbsImages()}
          </div>
        </div>
      }
      <GalleryFooter type={galleryType} setType={setGalleryType} />
      <Cursor outer={isScrollImageHovered || thumbsHover} />
    </div>
  )
}