import { useTranslation } from 'react-i18next';
import { ReactComponent as Dark } from '../assets/dark.svg';
import { ReactComponent as Light } from '../assets/light.svg';

import { ReactComponent as Scroll } from '../assets/scroll.svg';
import { ReactComponent as Thumbs } from '../assets/thumbs.svg';

import '../styles/GalleryFooter.css';
import { isPortrait } from '../utils';

export default function GalleryFooter(props) {

  const { t } = useTranslation();

  return (
    <footer className="gallery">
      { (['scroll', 'thumbs'].indexOf(props.type) != -1 && !isPortrait()) &&
        <div className="options">

          
          <div className='themeChooser'>
            <Dark className='selected' />
            <div className='separator'></div>
            
            <Light />
          </div>  


          <div className="type">
            <button
              className={props.type == 'scroll' ? 'selected' : ''}
              onClick={() => props.setType('scroll')}
            >
              <Scroll />
              {t('footer.options.scroll')}
            </button>

            <button
              className={props.type == 'thumbs' ? 'selected' : ''}
              onClick={() => props.setType('thumbs')}
            >
              <Thumbs />
              {t('footer.options.thumbs')}
            </button>
          </div>

        </div> 
      }
      { (['scroll', 'thumbs'].indexOf(props.type) != -1 && isPortrait()) &&
        <div className="options">


          <div className="type">
            <Scroll
              className={props.type == 'scroll' ? 'selected' : ''} 
              onClick={() => props.setType('scroll')}
            />
            <Thumbs 
              className={props.type == 'thumbs' ? 'selected' : ''}
              onClick={() => props.setType('thumbs')}
            />
          </div>

        </div> 
      }

      { (props.type === 'error' && !isPortrait()) &&
        <div className="options">

          
          <div className='themeChooser'>
            <Dark className='selected' />
            <div className='separator'></div>
            
            <Light />
          </div>  

        </div> 
      }

      { props.type == 'painting' &&
        <>
          <div className='options'>
            <div className='infos'>
              <h2>{props.details.title}</h2>
              <h3>{props.details.artist_name}</h3>
            </div>  


            <div className="ar">
              <h4>[ {t('footer.ar.title')} ]</h4>
              <h5>{t('footer.ar.subtitle')}</h5>
            </div>
          </div>
          <div className='buyButton'>
            {t('footer.buy')} · <span className='price'>{props.details.price} $</span>
          </div>
        </>
      }


      { (props.type == 'scroll' && !isPortrait()) && 
        <div className='scrollerContainer'>

          {/* <<Mouse className="scroller" />> */}
          <div className='bar'></div>

          <p>{t('footer.scroller')}</p>

        </div>
      }

    </footer>
  )
}